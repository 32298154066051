import PoView from '../views/PoView';
import DeliveryScheduleView from '../views/DeliveryScheduleView';
import DeliveredItems from '../views/DeliveredItems';
import LogsView from '../views/LogsView';
import SalesView from '../views/SalesView';
import CustomerView from '../views/CustomerView';


export const redirect_path = '/po'; //redirect path if successfully logged in
export const API = process.env.REACT_APP_API_URL; //api link

//https://ant.design/components/icon/ for icons
export const routes = [ //array for routes
  {
    component: PoView,
    title: 'purchase order',
    icon: 'file-invoice',
    view: true, //change to false if you dont want this route to be on sidebar menu
    path: '/po'
  },

  {
    component: DeliveryScheduleView,
    title: 'delivery schedule',
    icon: 'calendar',
    view: true, //change to false if you dont want this route to be on sidebar menu
    path: '/deliveries'
  },
  {
    component: DeliveredItems,
    title: 'delivered items',
    icon: 'calendar-check',
    view: true, //change to false if you dont want this route to be on sidebar menu
    path: '/delivered'
  },
  {
    component: CustomerView,
    title: 'customers',
    icon: 'user',
    view: true, //change to false if you dont want this route to be on sidebar menu
    path: '/customers'
  },
  {
    component: SalesView,
    title: 'Sales Report',
    icon: 'chart-bar',
    view: true, //change to false if you dont want this route to be on sidebar menu
    path: '/sales'
  },
  {
    component: LogsView,
    title: 'Logs',
    icon: 'history',
    view: true, //change to false if you dont want this route to be on sidebar menu
    path: '/logs'
  }
]