import React, { createContext, useReducer } from 'react';
import moment from 'moment';
//api
import axios from 'axios';
import { API } from '../../config/config';

import {
  reducer,
  GET_ITEMDELIVERIES,
  ADD_DELIVERY,
  EDIT_DELIVERY,
  DELETE_DELIVERY,
  RESET_DELIVERYDETAILS,
  GET_DELIVERIES
} from './DeliveriesReducer';
import { displayErrors, displayNotification } from '../../config/display';
import { headers, decodedToken } from '../../config/token';
//action

export const DelContext = createContext({});

function DeliveriesContext({ children }) {
  const initialState = {
    itemDeliveryDetails: [],
    deliveredItems: [],
    deliveredLength: 0
  };
  const [deliveries, dispatch] = useReducer(reducer, initialState);
  const { username, type } = decodedToken();
  const userDetails = { username, type };

  //functions
  const getItemDeliveries = (id) => axios.get(API + `cposms/poitems/delivery/${id}`, headers())
    .then(res => {
      const { itemQuantity, itemDelivered, itemRemaining, item_id, itemDeliveries } = res.data;

      dispatch({
        type: GET_ITEMDELIVERIES,
        payload: {
          itemDeliveryDetails: {
            item_id,
            itemQuantity,
            itemDelivered,
            itemRemaining,
            itemDeliveries
          }
        }
      })
    })
    .catch(err => {
      displayErrors(err);
    })

  const addItemDelivery = (values, updatePoItem, setFormConfig, setSubmitting) => {
    values.totalQty = values.quantity + values.underrun;
    axios.post(API + 'cposms/poitems/delivery', values, headers())
      .then(res => {
        const {
          newDelivery,
          itemRemaining,
          itemQuantity,
          itemDelivered,
          updatedItem,
          message
        } = res.data;

        dispatch({
          type: ADD_DELIVERY,
          payload: {
            newDelivery,
            itemRemaining,
            itemQuantity,
            itemDelivered,
          }
        })

        displayNotification("success", message);
        updatePoItem(updatedItem);
        setFormConfig({ visible: false });
        setSubmitting(false);
      })
      .catch(err => {
        displayErrors(err);
        setSubmitting(false);
      })

  }

  const editItemDelivery = (values, updatePoItem, setFormConfig, setSubmitting) => {
    values.totalQty = values.quantity + values.underrun;

    axios.put(API + 'cposms/poitems/delivery/' + values.id, values, headers())
      .then(res => {
        const {
          updatedDelivery,
          itemRemaining,
          itemQuantity,
          itemDelivered,
          updatedItem,
          message
        } = res.data;

        dispatch({
          type: EDIT_DELIVERY,
          payload: {
            updatedDelivery,
            itemRemaining,
            itemQuantity,
            itemDelivered,
          }
        })

        displayNotification("success", message);
        updatePoItem(updatedItem);
        setFormConfig({ visible: false });
        setSubmitting(false);
      })
      .catch(err => {
        displayErrors(err);
        setSubmitting(false);
      })
  }

  const deleteDelivery = (id, setFormConfig, updatePoItem) => {

    axios.delete(API + 'cposms/poitems/delivery/' + id, headers())
      .then(res => {
        const {
          id,
          itemRemaining,
          itemQuantity,
          itemDelivered,
          updatedItem,
          message
        } = res.data;

        dispatch({
          type: DELETE_DELIVERY,
          payload: {
            id,
            itemRemaining,
            itemQuantity,
            itemDelivered,
          }
        })

        displayNotification("success", message);
        updatePoItem(updatedItem);
        setFormConfig({ visible: false });

      })
      .catch(err => {
        displayErrors(err);
      })
  }

  const resetDeliveryDetails = () => {
    dispatch({
      type: RESET_DELIVERYDETAILS,
    })
  }

  const viewAllDelivered = (setLoading, filter, recordCount = 500) => {
    setLoading(true);
    let parameters = '';

    if (filter.start)
      parameters += `&start=${filter.start}`;

    if (filter.end)
      parameters += `&end=${filter.end}`;

    axios.get(API + `cposms/poitems/delivery?recordCount=${recordCount}${parameters}`, headers())
      .then(res => {
        const { deliveredItems } = res.data;

        dispatch({
          type: GET_DELIVERIES,
          payload: {
            deliveredItems
          }
        })
        setLoading(false);

      })
      .catch(err => {
        setLoading(false);
        displayErrors(err);
      })

  }

  const exportDeliveredItems = (setLoading, filter, recordCount = 500) => {

    let parameters = '';

    if (filter.start)
      parameters += `&start=${filter.start}`;

    if (filter.end)
      parameters += `&end=${filter.end}`;

    const dateNow = moment().format('Y_M_D');
    axios({
      url: API + `cposms/poitems/delivery/export-csv/dl?recordCount=${recordCount}${parameters}`,
      method: 'get',
      responseType: 'blob',
      ...headers()
    })
      .then(res => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `PoDeliveredItems_report_as_of_${dateNow}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setLoading(false);

      })
      .catch(err => {
        displayErrors(err);
        setLoading(false);

      })

  }

  return (
    <DelContext.Provider value={
      {
        deliveries,
        getItemDeliveries,
        addItemDelivery,
        editItemDelivery,
        deleteDelivery,
        resetDeliveryDetails,
        viewAllDelivered,
        userDetails,
        exportDeliveredItems
      }
    }>
      {children}
    </DelContext.Provider>
  )
}

export default DeliveriesContext
