import React, { createContext, useReducer } from 'react';
import axios from 'axios';

import {
  reducer,
  GET_CUSTOMERS,
  ADD_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
} from './CustomerReducer';
import { displayErrors, displayNotification } from '../../config/display';
import { headers } from '../../config/token';

const CustomerContext = createContext({});
export const CustomerProvider = ({ children }) => {
  const API = process.env.REACT_APP_API_URL || 'http://yourapi.com/api/';
  const initialValue = {
    customers: [],
    isLoaded: false,
  }
  const [customersVal, dispatch] = useReducer(reducer, initialValue);
  const getCustomers = async (setLoading = () => {}) => {
    setLoading(true)

    try {
      const res = await axios.get(`${API}cposms/customers`, headers())
      const { customers } = res.data;
      dispatch({
        type: GET_CUSTOMERS,
        payload: {
          customers,
        }
      })
    } catch (err) {
      displayErrors(err);
    } finally {
      setLoading(false);
    }
  }

  const addCustomer = async (values, setLoading, resetForm, setSubmitting) => {
    setLoading(true)

    try {
      const res = await axios.post(`${API}cposms/customers`, values, headers())
      const { newCustomer, message } = res.data;
      dispatch({
        type: ADD_CUSTOMER,
        payload: {
          newCustomer,
        }
      });
      displayNotification("success", message);
    } catch (err) {
      displayErrors(err);
    } finally {
      setLoading(false);
      setSubmitting(false);
      resetForm();
    }
  }

  const updateCustomer = async (values, setLoading, closeModal, setSubmitting) => {
    setLoading(true);
    try {
      const res = await axios.put(`${API}cposms/customers/${values.id}`, values, headers())
      const { newCustomer, message } = res.data;
      dispatch({
        type: UPDATE_CUSTOMER,
        payload: {
          newCustomer,
        }
      });
      displayNotification("success", message);
    } catch (err) {
      displayErrors(err);
    } finally {
      setLoading(false);
      setSubmitting(false);
      closeModal();
    }
  }

  const deleteCustomer = async (id, setLoading) => {
    setLoading(true)
    try {
      const res = await axios.delete(`${API}cposms/customers/${id}`, headers())
      const { message } = res.data;
      dispatch({
        type: DELETE_CUSTOMER,
        payload: {
          id,
        }
      });
      displayNotification("success", message);
    } catch (err) {
      displayErrors(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <CustomerContext.Provider
      value={{
        customersVal,
        getCustomers,
        addCustomer,
        updateCustomer,
        deleteCustomer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

export default CustomerContext
