import React, { Fragment, useState, lazy, useContext } from 'react';
import { Utils } from '../context/UtilsContext';
import Layout from '../layout/Layout';

// import DeliverySchedule from '../components/DeliverySchedule/DeliverySchedule';
const DeliveryCalendar = lazy(() =>
	import('../components/DeliverySchedule/DeliveryCalendar')
);
const DeliverySchedule = lazy(() =>
	import('../components/DeliverySchedule/DeliverySchedule')
);
const EmailModal = lazy(() => import('../components/EmailModal/EmailModal'));

const DeliveryScheduleView = () => {
	const [modalConfig, setModalConfig] = useState({ visible: false });
	const [emailModalConfig, setEmailModalConfig] = useState({ visible: false });
	const { setLoading } = useContext(Utils);

	const closeEmailModal = () => {
		setEmailModalConfig({ visible: false });
	};

	const openEmailModal = () => {
		setEmailModalConfig({ visible: true });
	};

	return (
		<Fragment>
			<br />
			<h2>DELIVERY SCHEDULE</h2>
			{modalConfig.visible && (
				<DeliverySchedule setModal={setModalConfig} date={modalConfig.date} />
			)}

			{emailModalConfig.visible && (
				<EmailModal closeEmailModal={closeEmailModal} setLoading={setLoading} />
			)}
			<DeliveryCalendar
				openEmailModal={openEmailModal}
				onSelect={setModalConfig}
			/>
		</Fragment>
	);
};

export default Layout(DeliveryScheduleView);
