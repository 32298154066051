import React, { useEffect, useState } from 'react';
import { Modal, Table, Spin } from 'antd';

const PoDeliverySchedule = props => {
  const {
    itemDeliverySchedule: { id },
    closeModal,
    getPoDeliverySchedule,
  } = props;
  const [itemSchedule, setItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getPoDeliverySchedule(id)
      .then(res => {
        setItems(res);
      })
      .finally(() => setLoading(false));
  },[]);

  const columns = [
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Date',
      align: 'center'
    },
    {
      key: 'quantity',
      dataIndex: 'quantity',
      title: 'Quantity',
      align: 'center'
    },
    {
      key: 'remaining',
      dataIndex: 'remaining',
      title: 'Remaining',
      align: 'center'
    },
    {
      key: 'remarks',
      dataIndex: 'remarks',
      title: 'Remarks',
      align : 'center'
    },
    {
      key: 'committed_qty',
      dataIndex: 'commited_qty',
      title: 'Prod. qty',
      align: 'center'
    },
    {
      key: 'prod_remarks',
      dataIndex: 'prod_remarks',
      title: 'Prod. remarks'
    },
    {
      key: 'others',
      dataIndex: 'others',
      title: 'Others'
    }
  ];

  return (
    <Modal
      visible={true}
      title="Item Schedule"
      width="60%"
      onCancel={() => isLoading ? null : closeModal({ visible: false })}
      footer={null}>
      <Spin spinning={isLoading}>
        <Table
          bodyStyle={{ overflowX: 'auto' }}
          bordered={true}
          size="small"
          columns={columns}
          dataSource={itemSchedule}
          pagination={false}
          rowKey="id"
        />
      </Spin>
    </Modal>
  )
}

export default PoDeliverySchedule
