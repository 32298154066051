import React, { useContext, useEffect, useState } from 'react';
import { Button, Input } from 'antd';

import Layout from '../layout/Layout';
import { Utils } from '../context/UtilsContext';
import CustomerContext from '../context/Customer/CustomerContext';
import CustomersTable from '../components/Customers/CustomersTable';
import CustomersForm from '../components/Customers/CustomersForm';
import searchArray from '../components/searchArray';

const CustomerView = () => {
  const {
    customersVal,
    getCustomers,
    addCustomer,
    updateCustomer,
    deleteCustomer,
  } = useContext(CustomerContext);
  const { setLoading } = useContext(Utils);

  const [modalData, setModalData] = useState({ visible: false, data: {} });
  const [search, setSearch] = useState('');

  const openModal = (data = {}) => setModalData({ data, visible: true });
  const closeModal = () => setModalData({ visible: false, data: {} });

  useEffect(() => {
    if(!customersVal.isLoaded)
      getCustomers(setLoading);
  }, [setLoading, customersVal, getCustomers]);

  const { customers } = customersVal;
  const filteredCustomer = searchArray(search, customers,['bi_name']);
  return (
    <>
      <br />
      <h4 style={{ fontWeight: 600 }}>CUSTOMERS LIST</h4> 
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px'
        }}
      >
        <Button
          icon="form"
          className="bl-cl"
          onClick={() => openModal()}
        >Add Record</Button>
        <Input.Search
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          style={{ width: '100%', maxWidth: 400, marginLeft: 10 }}
        />
      </div>
      
      <CustomersForm
        data={modalData.data}
        visible={modalData.visible}
        closeModal={closeModal}
        addCustomer={addCustomer}
        updateCustomer={updateCustomer}
        setLoading={setLoading}
      />
      <CustomersTable
        setLoading={setLoading}
        openModal={openModal}
        data={filteredCustomer}
        deleteCustomer={deleteCustomer}
      />
    </>
  )
}

export default Layout(CustomerView)
