import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'antd';

import { PoContext } from '../context/PurchaseOrder/PurchaseOrderContext';
import { Utils } from '../context/UtilsContext';
import PoItemlistFilter from '../components/Filter/PoItemlistFilter';
import PoItemList from '../components/PurchaseOrder/PoItemList';
import PoViewDeliveredItem from '../components/PoDelivery/PoViewDeliveredItem';
import PoDeliverySchedule from '../components/PurchaseOrder/PoDeliverySchedule';
import OverallDetailsModal from '../components/OverallDetails/OverallDetailsModal';

const PoItemView = ({ customers }) => {
	const [itemDeliveryDetails, setItemDeliveryDetails] = useState({	visible: false }); // viewing modal of deliveries, id
	const [itemDeliverySchedule, setItemDeliverySchedule] = useState({ visible: false });
	const [overallDetails, setOverallDetails] = useState({ visible: false });

	const {
		purchaseOrder,
		getPoItems,
		getPoDeliverySchedule,
		exportPoItemCsv,
	} = useContext(PoContext);
	const { setLoading } = useContext(Utils);
	//filter
	const defaultFilter = {
		search: null,
		sort: 'latest',
		customer: null,
		status: null,
		deliveryDue: null,
	};
	const [filter, setFilter] = useState(defaultFilter);
	const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 });
	const onFilter = () => getPoItems(setLoading, filter);
	const onResetFilter = () => {
		setFilter(defaultFilter);
		getPoItems(setLoading, defaultFilter);
	};

	const shouldFetchFromServer = ({ page, pageSize }) => {
		const totalData = page * pageSize;
		const currentDataLength = purchaseOrder.poItems.length; //
		const dataTotalLength = purchaseOrder.poItemsLength;
		let shouldReload = false;
		if (
			totalData > currentDataLength &&
			(dataTotalLength > totalData ||
				(dataTotalLength > totalData - pageSize &&
					dataTotalLength > currentDataLength))
		) {
			shouldReload = true;
		}

		const shouldLoadAll =
			(totalData > dataTotalLength && dataTotalLength > totalData - pageSize) ||
			totalData === dataTotalLength
				? `&start=${currentDataLength}&end=${dataTotalLength}`
				: ``;

		return {
			shouldReload,
			newPage: Math.floor(currentDataLength / 1000) + 1,
			shouldLoadAll,
		};
	};

	const onPageChange = (page, pageSize) => {
		const { shouldReload, newPage, shouldLoadAll } = shouldFetchFromServer({
			page,
			pageSize,
		});
		if (shouldReload) {
			getPoItems(setLoading, filter, newPage, shouldLoadAll);
		}
		setPaginate({ page, pageSize });
	};

	const onSizeChange = (page, pageSize) => {
		const { shouldReload, newPage, shouldLoadAll } = shouldFetchFromServer({
			page,
			pageSize,
		});
		if (shouldReload) {
			getPoItems(setLoading, filter, newPage, shouldLoadAll);
		}
		setPaginate({ page, pageSize });
	};
	//end filter
	//search & records
	const refreshContent = () => {
		getPoItems(setLoading, defaultFilter);
		setPaginate({ page: 1, pageSize: 10 });
	};
	//end
	useEffect(() => getPoItems(setLoading, defaultFilter), []);

	return (
		<>
			<br />
			<PoItemlistFilter
				filter={filter}
				setFilter={setFilter}
				onFilter={onFilter}
				onResetFilter={onResetFilter}
				customers={customers}
			/>

			<Button
				className="bl-cl"
				onClick={() => exportPoItemCsv(setLoading, filter)}
				style={{ marginRight: 5 }}
				icon="file-excel"
			>
				Export to CSV
			</Button>
			<Button
				className="bl-cl"
				onClick={refreshContent}
				style={{ marginRight: 5 }}
				icon="sync"
			>
				Refresh content
			</Button>

			<PoItemList
				setItemDeliveryDetails={setItemDeliveryDetails}
				poItems={purchaseOrder.poItems}
				setItemDeliverySchedule={setItemDeliverySchedule}
				setOverallDetails={setOverallDetails}
				customers={purchaseOrder.customers}
				onPageChange={onPageChange}
				onSizeChange={onSizeChange}
				paginate={paginate}
				pageLength={purchaseOrder.poItemsLength}
			/>

			{itemDeliveryDetails.visible && (
				<PoViewDeliveredItem
					itemDeliveryDetails={itemDeliveryDetails}
					setItemDeliveryDetails={setItemDeliveryDetails}
					setLoading={setLoading}
				/>
			)}

			{itemDeliverySchedule.visible && (
				<PoDeliverySchedule
					getPoDeliverySchedule={getPoDeliverySchedule}
					itemDeliverySchedule={itemDeliverySchedule}
					closeModal={setItemDeliverySchedule}
					itemSchedule={purchaseOrder.itemSchedule}
					setLoading={setLoading}
				/>
			)}

			{overallDetails.visible && (
				<OverallDetailsModal
					overallDetails={overallDetails}
					setOverallDetails={setOverallDetails}
					setLoading={setLoading}
				/>
			)}
		</>
	);
};

export default PoItemView;
