import React, { Fragment } from 'react';
import moment from 'moment';
import { Table, Button, Tooltip, Badge } from 'antd';

function PoItemList(props) {
	const {
		poItems,
		setItemDeliverySchedule,
		setItemDeliveryDetails,
		customers,
		setOverallDetails,
		pageLength,
		paginate,
		onPageChange,
		onSizeChange,
	} = props;

	const tableColumns = [
		{
			key: 'btnAddDelivery',
			align: 'center',
			render: data => {
				return (
					<Tooltip placement="right" title="Add/View delivered details">
						<Badge dot={data.hasDelivery > 0 ? true : false}>
							<Button
								onClick={() => setItemDeliveryDetails({ visible: true, id: data.id })}
								className="bl-cl"
								icon="carry-out"
								size="small"
							></Button>
						</Badge>
					</Tooltip>
				);
			},
		},
		{
			key: 'btnSchedule',
			align: 'center',
			render: data => {
				return (
					<Tooltip placement="right" title="View delivery schedule">
						<Badge dot={data.hasSchedule > 0 ? true : false}>
							<Button
								onClick={() => setItemDeliverySchedule({ visible: true, id: data.id })}
								className="bl-cl"
								icon="calendar"
								size="small"
							></Button>
						</Badge>
					</Tooltip>
				);
			},
		},
		{
			key: 'btnOverview',
			align: 'center',
			render: data => {
				return (
					<Tooltip placement="right" title="View item progress">
						<Button
							onClick={() =>
								setOverallDetails({
									visible: true,
									id: data.id,
									item: data.itemdesc,
									po: data.po_num,
								})
							}
							className="bl-cl"
							icon="book"
							size="small"
						></Button>
					</Tooltip>
				);
			},
		},
		{
			key: 'status',
			title: 'Status',
			align: 'center',
			render: data => {
				const deliveryDate = moment(data.deliverydate);
				const diff = deliveryDate.diff(moment(), 'days');
				const hasWarning = diff < 3 && data.status === 'OPEN';
				const style =
					data.status === 'OPEN'
						? hasWarning
							? {
									color: '#e74c3c', // if has warning
							  }
							: {
									color: '#27ae60', //normal status
							  }
						: {
								color: '#218c74', // served
						  };
				return <div style={{ ...style, fontWeight: 600 }}>{data.status}</div>;
			},
			filters: [
				{
					text: 'OPEN',
					value: 'OPEN',
				},
				{
					text: 'SERVED',
					value: 'SERVED',
				},
			],
			onFilter: (value, record) => record.status.indexOf(value) === 0,
			sorter: (a, b) => {
				const A = a.status ? a.status : '';
				const B = b.status ? b.status : '';
				return A.localeCompare(B);
			},
		},
		{
			key: 'customer',
			title: 'Customer',
			dataIndex: 'customer',
			filters: customers.map(customer => ({
				text: customer.companyname,
				value: customer.companyname,
			})),
			onFilter: (value, record) => record.customer.indexOf(value) === 0,
			sorter: (a, b) => {
				const A = a.customer ? a.customer : '';
				const B = b.customer ? b.customer : '';
				return A.localeCompare(B);
			},
		},
		{
			key: 'po_num',
			title: 'PO No.',
			dataIndex: 'po_num',
			sorter: (a, b) => {
				const A = a.po_num ? a.po_num : '';
				const B = b.po_num ? b.po_num : '';
				return A.localeCompare(B);
			},
		},
		{
			key: 'code',
			title: 'Code',
			dataIndex: 'code',
			sorter: (a, b) => {
				const A = a.code ? a.code : '';
				const B = b.code ? b.code : '';
				return A.localeCompare(B);
			},
		},
		{
			key: 'partnum',
			title: 'Part no.',
			dataIndex: 'partnum',
			sorter: (a, b) => {
				const A = a.partnum ? a.partnum : '';
				const B = b.partnum ? b.partnum : '';
				return A.localeCompare(B);
			},
		},
		{
			key: 'itemdesc',
			title: 'Item Description',
			dataIndex: 'itemdesc',
			sorter: (a, b) => {
				const A = a.itemdesc ? a.itemdesc : '';
				const B = b.itemdesc ? b.itemdesc : '';
				return A.localeCompare(B);
			},
		},
		{
			key: 'unit',
			title: 'Unit',
			dataIndex: 'unit',
			align: 'center',
			sorter: (a, b) => {
				const A = a.unit ? a.unit : '';
				const B = b.unit ? b.unit : '';
				return A.localeCompare(B);
			},
		},
		// {
		//   key: 'currency',
		//   title: 'Currency',
		//   dataIndex: 'currency',
		//   align: 'center',
		//   sorter: (a, b) => {
		//     const A = a.currency ? a.currency : '';
		//     const B = b.currency ? b.currency : '';
		//     return A.localeCompare(B);
		//   },
		// },
		{
			key: 'quantity',
			title: 'Delivered / Qty',
			align: 'center',
			render: data => data.delivered_qty + ' / ' + data.quantity,
			sorter: (a, b) => {
				return a.quantity - b.quantity;
			},
		},
		{
			key: 'unitprice',
			title: 'Unit Price',
			dataIndex: 'unitprice',
			align: 'center',
			sorter: (a, b) => {
				return a.unitprice - b.unitprice;
			},
		},
		{
			key: 'deliverydate',
			title: 'Del. Date',
			dataIndex: 'deliverydate',
			filters: [
				{
					text: '7 DAYS',
					value: 7,
				},
				{
					text: '15 DAYS',
					value: 15,
				},
				{
					text: '30 DAYS',
					value: 30,
				},
			],
			onFilter: (value, record) => moment(record.deliverydate).diff(moment(), 'days') < value,
			sorter: (a, b) => {
				const A = a.deliverydate ? a.deliverydate : '';
				const B = b.deliverydate ? b.deliverydate : '';
				return moment(A).unix() - moment(B).unix();
			},
		},
		{
			key: 'remarks',
			title: 'Remarks',
			dataIndex: 'remarks',
			sorter: (a, b) => {
				const A = a.remarks ? a.remarks : '';
				const B = b.remarks ? b.remarks : '';
				return A.localeCompare(B);
			},
		},
	];

	return (
		<Fragment>
			<Table
				bordered={true}
				size="small"
				dataSource={poItems}
				columns={tableColumns}
				rowKey="id"
				bodyStyle={{ overflowX: 'auto' }}
				style={{ margin: '10px 0 10px 0' }}
				pagination={{
					total: pageLength,
					current: paginate.page,
					pageSize: paginate.pageSize,
					defaultPageSize: paginate.pageSize,
					showSizeChanger: true,
					onChange: onPageChange,
					onShowSizeChange: onSizeChange,
					pageSizeOptions: ['10', '15', '25'],
					showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
					position: 'both',
				}}
			/>
		</Fragment>
	);
}

export default PoItemList;
