import React, { createContext, useReducer } from 'react';
import { reducer } from './PurchaseOrderReducer';
import moment from 'moment';
//api
import axios from 'axios';
import {
	GET_POITEM,
	ADD_PO,
	GET_PO,
	UPDATE_PO,
	CANCEL_PO,
	FETCH_OPTIONPO,
	UPDATE_POITEM,
	GET_ITEMSCHEDULE,
	ENDORSE_PO,
} from './PurchaseOrderReducer';
import { displayErrors, displayNotification } from '../../config/display';
import { headers, decodedToken } from '../../config/token';
import { API } from '../../config/config';
//action

export const PoContext = createContext({});

function PurchaseOrderContext({ children }) {
	const companyName = process.env.REACT_APP_COMPANY || 'no_name';
	const initialState = {
		customers: [],
		unitsOption: [],
		itemSelectionList: [],
		itemSchedule: [],
		poItems: [],
		poItemsLength: 0,
		po: [],
		poLength: 0,
	};

	const [purchaseOrder, dispatch] = useReducer(reducer, initialState);
	const { username, type } = decodedToken();
	const userDetails = { username, type };

	const updatePoItem = (newItem) => {
		dispatch({
			type: UPDATE_POITEM,
			payload: {
				newItem,
			},
		});
	};

	const getSelectOptions = () =>
		axios
			.get(`${API}cposms/option/poselect`, headers())
			.then((res) => {
				const { customers, unitsOption } = res.data;
				dispatch({
					type: FETCH_OPTIONPO,
					payload: {
						customers,
						unitsOption,
					},
				});
				return res;
			})
			.catch((err) => {
				displayErrors(err);
			});

	const getCustomerItems = (customer) =>
		axios
			.get(`${API}cposms/option/customeritems?customer=${customer}`, headers())
			.then((res) => res)
			.catch((err) => {
				displayErrors(err);
				throw new Error(err);
			});

	const getPoItems = (
		setLoading,
		filter = {},
		page = 1,
		shouldLoadAll = ``
	) => {
		setLoading(true);
		const parameters = getFilterParams(filter);
		axios
			.get(
				`${API}cposms/poitems?page=${page}${parameters}${shouldLoadAll}`,
				headers()
			)
			.then((res) => {
				const { poItems, poItemsLength } = res.data;

				dispatch({
					type: GET_POITEM,
					payload: {
						poItems,
						poItemsLength,
						page,
					},
				});
				setLoading(false);
			})
			.catch((err) => displayErrors(err))
			.finally(() => setLoading(false));
	};
	const getFilterParams = (filter) => {
		let params = ``;
		for (const prop in filter) {
			params += filter[prop] ? `&${prop}=${filter[prop]}` : '';
		}
		return params;
	};

	const getPO = (setLoading, filter = {}, page = 1, shouldLoadAll = ``) => {
		setLoading(true);
		const parameters = getFilterParams(filter);
		axios
			.get(
				API + `cposms/po?page=${page}${parameters}${shouldLoadAll}`,
				headers()
			)
			.then((res) => {
				const { po, poLength } = res.data;
				dispatch({
					type: GET_PO,
					payload: {
						po,
						page,
						poLength,
					},
				});
			})
			.catch((err) => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const getPoItem = (id) =>
		axios
			.get(API + `cposms/po/${id}`, headers())
			.then((res) => {
				const { items } = res.data;
				return { items };
			})
			.catch((err) => {
				displayErrors(err);
				throw new Error();
			});

	const addPo = (values, resetForm, setSubmitting) => {
		axios
			.post(API + 'cposms/po', values, headers())
			.then((res) => {
				const { newItem, message } = res.data;

				dispatch({
					type: ADD_PO,
					payload: {
						newItem,
					},
				});
				setSubmitting(false);
				resetForm();
				displayNotification('success', message);
			})
			.catch((err) => {
				displayErrors(err);
				setSubmitting(false);
			});
	};

	const editPo = (values, closeModal, setSubmitting) => {
		axios
			.put(API + `cposms/po/${values.id}`, values, headers())
			.then((res) => {
				const { newItem, message } = res.data;

				dispatch({
					type: UPDATE_PO,
					payload: {
						newItem,
					},
				});
				displayNotification('success', message);
				closeModal();
			})
			.catch((err) => {
				displayErrors(err);
				setSubmitting(false);
			});
	};

	const cancelPo = (id, remarks, setPoDetails) => {
		if (remarks === undefined || remarks.trim() === '') {
			displayNotification('error', 'Input remarks');
			return false;
		}

		axios
			.delete(API + `cposms/po/${id}?remarks=${remarks}`, headers())
			.then((res) => {
				const { message } = res.data;

				dispatch({
					type: CANCEL_PO,
					payload: {
						id,
					},
				});

				displayNotification('success', message);
				setPoDetails({ visible: false });
			})
			.catch((err) => {
				displayErrors(err);
				setPoDetails({ visible: false });
			});
	};

	const getPoDeliverySchedule = (id) => axios.get(API + `cposms/poitems/schedules/${id}`, headers())
		.then((res) => {
			const { itemSchedule } = res.data;
			return itemSchedule;
		})
		.catch((err) => displayErrors(err));
	

	const exportPoCsv = (setLoading, filter = {}) => {
		setLoading(true);
		const dateNow = moment().format('Y_M_D');
		const parameters = getFilterParams(filter);
		axios({
			url: API + `cposms/po/export-csv/dl?format=csv${parameters}`,
			method: 'get',
			responseType: 'blob',
			...headers(),
		})
			.then((res) => {
				displayNotification('success', 'File successfully generated');
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('target', '_blank');
				link.setAttribute(
					'download',
					`PurcaseOrders_report_as_of_${dateNow}.xlsx`
				);
				document.body.appendChild(link);
				link.click();
				link.parentElement.removeChild(link);
				setLoading(false);
			})
			.catch((err) => {
				displayErrors(err);
				setLoading(false);
			});
	};

	const exportPoItemCsv = (setLoading, filter = {}) => {
		setLoading(true);
		const dateNow = moment().format('Y_M_D');
		const parameters = getFilterParams(filter);
		axios({
			url: API + `cposms/poitems/export-csv/dl?csv=true${parameters}`,
			method: 'get',
			responseType: 'blob',
			...headers(),
		})
			.then((res) => {
				displayNotification('success', 'File successfully generated');
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('target', '_blank');
				link.setAttribute(
					'download',
					`PurcaseOrdersItems_report_as_of_${dateNow}.xlsx`
				);
				document.body.appendChild(link);
				link.click();
				link.parentElement.removeChild(link);
			})
			.catch((err) => displayErrors(err))
			.finally(() => setLoading(false));
	};

	const endorsePo = (poDetails, setLoading) => {
		setLoading(true);
		axios
			.post(API + `mail/endorsepo?company=${companyName}`, poDetails, headers())
			.then((res) => {
				const { message, id } = res.data;
				dispatch({
					type: ENDORSE_PO,
					payload: {
						id,
					},
				});

				displayNotification('success', message);
			})
			.catch((err) => displayErrors(err))
			.finally(() => setLoading(false));
	};

	return (
		<PoContext.Provider
			value={{
				purchaseOrder,
				getSelectOptions,
				getPoItems,
				addPo,
				getPO,
				editPo,
				cancelPo,
				userDetails,
				updatePoItem,
				getPoDeliverySchedule,
				exportPoCsv,
				exportPoItemCsv,
				endorsePo,
				getPoItem,
				getCustomerItems,
			}}
		>
			{children}
		</PoContext.Provider>
	);
}

export default PurchaseOrderContext;
