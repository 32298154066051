import React, { Fragment } from 'react';
import { Collapse, Row, Col, Button, Input, Select, Icon } from 'antd';

const { Panel } = Collapse;
const { Option } = Select;

const PolistFilter = ({
	customers,
	filter,
	setFilter,
	onFilter,
	onResetFilter,
}) => {
	const customerOpt = customers.filter(customer => customer.isApproved === "APPROVED").map((data) => (
		<Option key={data.id} value={data.id}>
			{data.bi_name}
		</Option>
	));
	return (
		<Fragment>
			<Collapse style={{ margin: '10px 0 10px 0' }} defaultActiveKey={[1]}>
				<Panel header="ADVANCE FILTER" key="1">
					<Row gutter={16}>
						<Col lg={{ span: 6 }}>
							Search : <br />
							<Input
								value={filter.search}
								onChange={(e) =>
									setFilter({ ...filter, search: e.target.value })
								}
								placeholder="Input PO,CODE,ID,PN"
							/>
						</Col>
						<Col lg={{ span: 6 }}>
							Customer : <br />
							<Select
								onChange={(val) => setFilter({ ...filter, customer: val })}
								value={filter.customer}
								style={{ width: '100%' }}
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								showSearch
							>
								<Option value={null}>All</Option>
								{customerOpt}
							</Select>
						</Col>
						<Col lg={{ span: 6 }}>
							Status : <br />
							<Select
								value={filter.status}
								onChange={(val) => setFilter({ ...filter, status: val })}
								style={{ width: '100%' }}
							>
								<Option value={null}>All</Option>
								<Option value="OPEN">OPEN</Option>
								<Option value="SERVED">SERVED</Option>
							</Select>
						</Col>
						<Col lg={{ span: 6 }}>
							Delivery due : <br />
							<Select
								onChange={(val) => setFilter({ ...filter, deliveryDue: val })}
								value={filter.deliveryDue}
								style={{ width: '100%' }}
							>
								<Option value={null}>All</Option>
								<Option value={7}>7 Days</Option>
								<Option value={15}>15 Days</Option>
								<Option value={30}>30 Days</Option>
							</Select>
						</Col>
						<Col lg={{ span: 6 }}>
							Sort : <br />
							<Select
								value={filter.sort}
								onChange={(sort) => setFilter({ ...filter, sort })}
								style={{ width: '100%' }}
							>
								<Option value="latest">
									<Icon type="sort-descending" />
									Latest record
								</Option>
								<Option value="oldest">
									<Icon type="sort-ascending" />
									Oldest record
								</Option>
								<Option value="date-asc">
									<Icon type="sort-ascending" />
									Date ascending
								</Option>
								<Option value="date-desc">
									<Icon type="sort-ascending" />
									Date descending
								</Option>
							</Select>
						</Col>
						<Col lg={{ span: 6 }}>
							<br />
							<Button onClick={onFilter} className="bl-cl" icon="filter" block>
								Filter
							</Button>
						</Col>
						<Col lg={{ span: 6 }}>
							<br />
							<Button
								onClick={onResetFilter}
								className="bl-cl"
								icon="undo"
								block
							>
								Reset
							</Button>
						</Col>
					</Row>
				</Panel>
			</Collapse>
		</Fragment>
	);
};

export default PolistFilter;
